@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* src/App.css */
.nav-link .h-6.w-6 {
  font-size: 1.5rem;
}

.nav-link .text-xs {
  font-size: 0.75rem;
}

.nav-link.active {
  color: #f0ad4e; /* Color para el enlace activo */
}
/* styles.css o el archivo CSS que estés usando */
.fixed-bottom-nav {
  padding-bottom: env(safe-area-inset-bottom); /* Para dispositivos con notch */
}

.flex-justify-around > * {
  flex: 1;
  text-align: center;
}

.flex-col > * {
  display: flex;
  flex-direction: column;
  align-items: center;
}
/* styles.css o el archivo CSS que estés usando */
.navbar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0.5rem 0;
  background-color: rgba(0, 0, 0, 0.8); /* Fondo semi-transparente */
  backdrop-filter: blur(10px); /* Efecto de desenfoque */
}

.navbar .nav-link {
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 0.5rem;
}

.navbar .nav-link .h-6 {
  width: 24px;
  height: 24px;
}

.navbar .nav-link .text-xs {
  font-size: 0.75rem;
  margin-top: 0.25rem;
  color: white; /* Color blanco para el texto */
}

/* Asegurar que el contenido no se superponga con la barra de navegación */
body {
  padding-bottom: 70px; /* Ajustar según la altura de la barra de navegación */
}

/* Asegurar que la barra de navegación no se superponga con el contenido */
.navbar.fixed-bottom {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1000;
}
/* src/index.css */
.content-wrapper {
  padding-top: 56px; /* Altura de la barra superior */
  padding-bottom: 56px; /* Altura de la barra inferior */
}
/* src/index.css */
.content-wrapper {
  padding-top: 56px; /* Altura de la barra superior */
  padding-bottom: 56px; /* Altura de la barra inferior */
}

.navbar .nav-link {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.navbar .nav-link .icon {
  height: 24px; /* Tamaño del icono */
  width: 24px;
  color: gray;
}

.navbar .nav-link .text {
  font-size: 12px; /* Tamaño del texto */
  color: gray;
}

.navbar .nav-link.active .icon,
.navbar .nav-link.active .text {
  color: black; /* Color del texto e icono activos */
}
/* src/index.css */
.content-wrapper {
  padding-top: 56px; /* Altura de la barra superior */
  padding-bottom: 56px; /* Altura de la barra inferior */
}

.navbar .nav-link {
  display: flex;
  align-items: center;
}

.navbar .nav-link .icon {
  height: 24px; /* Tamaño del icono */
  width: 24px;
  color: gray;
}

.navbar .nav-link .text {
  font-size: 16px; /* Tamaño del texto */
  color: gray;
}

.navbar .nav-link.active .icon,
.navbar .nav-link.active .text {
  color: #000000; /* Color del texto e icono activos */
}

.navbar-brand img.logo-small {
  height: 30px; /* Ajustar el tamaño del logotipo */
  width: auto;  /* Mantener proporción */
}

/* src/index.css */
.login-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px; /* Ajusta este valor si es necesario */
}

.login-logo {
  max-width: 150px;
  margin-bottom: 20px;
}

.login-container {
  width: 100%;
  max-width: 400px;
  padding: 20px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.form-group {
  margin-bottom: 15px;
  text-align: left;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input {
  width: calc(100% - 20px);
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.error {
  color: red;
  margin-bottom: 15px;
}

/* index.css */
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Arial, sans-serif;
}

.content {
  padding-top: 70px; /* Ajusta este valor según la altura de tu TopBar */
  padding-bottom: 70px; /* Ajusta este valor según la altura de tu BottomNavigation */
  background-color: #f1f1f1;
}

.top-bar {
  height: 70px; /* Asegúrate de que este valor coincida con el padding-top en .content */
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.top-bar .logo {
  height: 40px;
  width: auto;
}

.top-bar .menu-icon {
  font-size: 24px;
  cursor: pointer;
}

.navbar.fixed-bottom {
  height: 70px; /* Asegúrate de que este valor coincida con el padding-bottom en .content */
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
}

.top-bar {
  z-index: 40; /* Asegúrate de que sea menor que el z-50 del ProductDrawer */
}

.bottom-navigation {
  z-index: 40; /* Asegúrate de que sea menor que el z-50 del ProductDrawer */
}

.container {
  padding-top: 70px; /* Ajusta este valor según la altura de tu top bar */
  padding-bottom: 70px; /* Ajusta este valor según la altura de tu bottom navigation */
  
}

.fixed-content {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
}
.drawer-z-index {
  z-index: 100;
}
.drawer-z-index {
  z-index: 1050; /* Ajusta según sea necesario */
}
/* Estilos básicos para la tabla */
.table-responsive {
  width: 100%;
  overflow-x: auto;  
}
/* Add these styles within your global CSS file or in a <style> tag if you're using CSS-in-JS */
.toggle-bg {
  background-color: #f3f4f6; /* A light gray background */
  border-radius: 9999px; /* Fully rounded ends of the toggle */
}

.toggle-button {
  background-color: #3b82f6; /* Blue background for the active part */
  border-radius: 9999px; /* Fully rounded button */
  color: white;
  text-align: center;
  transition: transform 0.3s ease-in-out; /* Smooth transition for moving the toggle */
}

/* src/components/BottomNavigation.css */
.navbar {
  height: 60px; /* Ajusta según sea necesario */
  padding: 0;
  background-color: #fff; /* Cambia el color de fondo a blanco */
  box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.1); /* Añade sombra para un efecto flotante */
}

.nav-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
  color: #7f8c8d; /* Color de texto para los íconos inactivos */
  text-decoration: none;
}

.icon {
  font-size: 24px;
  margin-bottom: 4px; /* Ajusta el espacio entre el ícono y el texto */
}

.nav-text {
  font-size: 12px;
  color: #7f8c8d; /* Color del texto */
}

.nav-link.active .icon, .nav-link.active .nav-text {
  color: #000; /* Color para el ícono y el texto activo */
}

/* src/components/TopBar.css */
.top-bar {
    height: 60px;
    background-color: #fff;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
    padding: 0 15px;
    z-index: 1000;
  }
  
  .logo {
    height: 40px; /* Ajusta según sea necesario */
    width: auto;
  }
  
  .page-title {
    font-size: 18px;
    font-weight: bold;
    margin-left: 10px;
  }
  
  .menu-icon {
    font-size: 24px;
    cursor: pointer;
  }
  